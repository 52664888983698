<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column flex-sm-row">
      <div class="d-flex flex-column mb-8 mb-sm-0">
        <h5 class="h5-text mb-2">Архивные запросы</h5>
        <p class="subhead-text grey--text mb-0">{{ countRequests(totalItems) }}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex flex-column-nono mb-8 mb-sm-0">
        <v-text-field
          class="mt-0 search-appended"
          hide-details
          background-color="white"
          v-model="search"
          name="search"
          prepend-inner-icon="$search"
          placeholder="Поиск"
          @input="searching"
          v-on:keyup.13="fetchCarSearchRequests()"
          outlined
        >
          <template v-slot:append-outer>
            <v-btn x-large color="primary" @click="fetchCarSearchRequests()">Ок</v-btn>
          </template>
        </v-text-field>

      </div>
    </div>
    <div class="mb-3">
      <v-checkbox
        data-v-model="showHidden"
        label="Показать доп колонки"
        @change="showAdditional()"
        hide-details
        v-show="!columnsLoading"
      ></v-checkbox>
      <div v-if="columnsLoading">Колонки загружаются...</div>
    </div>
    <v-data-table
      :headers="getHeaders"
      :items="requestsList.items"
      item-key="id"
      :hide-default-header="!isTableList"
      data-hide-default-footer="simpleTable || !isTableList"
      v-resize="checkTypeTable"
      :loading="isLoading"
      data-search="search"
      hide-default-footer
      :page.sync="currentPage"
      :items-per-page="itemsPerPage"
    >
      <template
        v-slot:no-data
      >
        Пока что запросов нет
      </template>
      <template
        v-slot:body="{ items }"
      >
        <tbody v-if="isTableList">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td class="body-l-text">
            <v-btn
              color="primary"
              depressed
              x-small
              link
              :to="{ name: 'CarSearchRequest', params: {id: item.id} }"
            >
              {{ item.id }}
            </v-btn>
          </td>
          <td class="body-l-text">{{ item.car }}</td>
          <td class="body-l-text">{{ formatDate(item.date) }}</td>
          <td class="body-l-text">{{ item.leasingCompany }}</td>
          <td v-if="item.manager" class="body-l-text">{{ item.manager.last_name }} {{ item.manager.first_name }}</td>
          <td v-else class="body-l-text"></td>
          <td v-if="item.manager && showHidden" class="body-l-text">{{ item.manager.email}}</td>
          <td class="body-l-text"> {{ item.dsCount }} </td>
          <td class="body-l-text" v-if="showHidden" v-html="item.dsNames.join(',<br>')"></td>
          <td class="body-l-text" v-if="showHidden" v-html="item.sendKP.join(',<br>')"></td>
          <td class="body-l-text" v-if="showHidden" v-html="item.kpDateDiff.join(',<br>')"></td>
          <td class="body-l-text"> {{ item.kpCount }} </td>
        </tr>
        </tbody>
        <div
          v-else
          class="table__cards"
        >
          <div
            v-for="item in items"
            class="table-card"
            :key="item.id"
          >
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('id', requestsList.headers) }} </div>
              <div class="body-l-text">
                <v-btn
                  color="primary"
                  depressed
                  x-small
                  link
                  :to="{ name: 'CarSearchRequest', params: {id: item.id} }"
                >
                  {{ item.id }}
                </v-btn>
              </div>
            </div>
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('car', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ item.car }} </div>
            </div>
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('date', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ formatDate(item.date) }} </div>
            </div>
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('leasingCompany', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ item.leasingCompany }}</div>
            </div>
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('manager', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ item.manager.last_name }} {{ item.manager.first_name }} </div>
            </div>
            <div class="table-card__item mb-8" v-if="showHidden">
              <div class="caption-text mb-2"> {{ findHeader('email', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ item.manager.email }} </div>
            </div>
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('dsCount', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ item.dsCount }}</div>
            </div>
            <div class="table-card__item mb-8" v-if="showHidden">
              <div class="caption-text mb-2"> {{ findHeader('dsNames', requestsList.headers) }} </div>
              <div class="body-l-text" v-html="item.dsNames.join(',<br>')"></div>
            </div>
            <div class="table-card__item mb-8" v-if="showHidden">
              <div class="caption-text mb-2"> {{ findHeader('sendKP', requestsList.headers) }} </div>
              <div class="body-l-text" v-html="item.sendKP.join(',<br>')"></div>
            </div>
            <div class="table-card__item mb-8" v-if="showHidden">
              <div class="caption-text mb-2"> {{ findHeader('kpDateDiff', requestsList.headers) }} </div>
              <div class="body-l-text" v-html="item.kpDateDiff.join(',<br>')"></div>
            </div>
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2"> {{ findHeader('kpCount', requestsList.headers) }} </div>
              <div class="body-l-text"> {{ item.kpCount }}</div>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
    <div>
      <v-pagination class="d-inline-block" v-model="currentPage" :length="pageCount" total-visible="10"
                    @input="navigate"></v-pagination>
      <v-select
        v-model="itemsPerPage"
        :items="pageSelect"
        @change="fetchCarSearchRequests"
        outlined
        dense
        class="d-inline-block"
      ></v-select>
    </div>
  </div>
</template>

<script>
// import Component from 'vue-class-component'
// import { Mixins, Prop } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import moment from 'moment'
import CarSearchRequest from '@/apis/CarSearchRequest'
import { Http } from '@/apis/Http'

export default {
  mixins: [TableMixin],
  data() {
    return {
      simpleTable: false,
      isLoading: true,
      showHidden: false,
      totalItems: 0,
      currentPage: 1,
      prevPage: 1,
      itemsPerPage: 20,
      pageCount: 1,
      columnsLoading: false,
      pageSelect: [
        { value: 10, text: 'показывать по 10' },
        { value: 20, text: 'показывать по 20' },
        { value: 50, text: 'показывать по 50' },
        { value: 1000, text: 'Все' }
      ],

      requestsList: {
        headers: [
          {
            text: 'Номер',
            align: 'start',
            value: 'id'
          },
          {
            text: 'Транспортное средство',
            align: 'start',
            value: 'car'
          },
          {
            text: 'Дата создания',
            align: 'start',
            value: 'date'
          },
          {
            text: 'Лизинговая компания',
            align: 'start',
            value: 'leasingCompany'
          },
          {
            text: 'Сотрудник',
            align: 'start',
            value: 'manager'
          },
          {
            text: 'Email',
            align: 'start',
            hidden: true,
            value: 'email'
          },
          {
            text: 'ДЦ',
            align: 'start',
            value: 'dsCount'
          },
          {
            text: 'Названия ДЦ',
            align: 'start',
            hidden: true,
            value: 'dsNames'
          },
          {
            text: 'Прислали КП',
            align: 'start',
            hidden: true,
            value: 'sendKP'
          },
          {
            text: 'Время ответа',
            align: 'start',
            hidden: true,
            value: 'kpDateDiff'
          },
          {
            text: 'КП',
            align: 'start',
            value: 'kpCount'
          }

        ],
        items: []
      }
    }
  },

  computed: {
    getHeaders() {
      if (this.showHidden) return this.requestsList.headers
      return this.requestsList.headers.filter(el => !el.hidden)
    },
    search: {
      get() {
        return this.$store.state.common.searchAdminRequests
      },
      set(item) {
        this.$store.state.common.searchAdminRequests = item
      }
    }
  },

  created() {
    this.fetchCarSearchRequests()
  },

  methods: {

    searching() {
      if (!this.search) this.fetchCarSearchRequests()
    },

    async showAdditional() {
      if (!this.showHidden) {
        await this.getAdditional()
      }
      this.showHidden = !this.showHidden
    },

    async getAdditional() {
      this.columnsLoading = true
      const ids = []
      for (const item of this.requestsList.items) ids.push(item.id)
      const res = await Http.get('/admin/car-search-requests/get-additional-columns', { params: { ids: ids } })
      const items = []
      for (const item of this.requestsList.items) {
        const row = res.data?.[item.id]
        if (row) {
          items.push({ ...item, ...row })
        }
      }
      this.requestsList.items = items
      this.columnsLoading = false
    },

    navigate() {
      if (this.currentPage === this.prevPage) return
      this.fetchCarSearchRequests()
    },

    fetchCarSearchRequests() {
      this.$setLoading(true)
      const params = {
        limit: this.itemsPerPage,
        offset: this.itemsPerPage === 1000 ? 0 : (this.currentPage - 1) * this.itemsPerPage,
        page: this.currentPage,
        search: this.search,
        archived: 1
      }
      CarSearchRequest.fetchAll(params)
        .then(async ({ data }) => {
          this.requestsList.items = data.requests
          this.isLoading = false
          // console.log(this.requestsList, this.requestsList.items)
          this.totalItems = data.total
          this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          this.prevPage = parseInt(this.currentPage)
          if (this.showHidden) this.getAdditional()
        })
        .finally(() => {
          this.$setLoading(false)
        })
    },

    formatDate(date) {
      return moment(date).format('LLL')
    },

    countRequests(number) {
      return stringUtils.pluralForm(number, ['запрос', 'запроса', 'запросов'])
    }
  }
}
</script>
